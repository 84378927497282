import { graphql, useStaticQuery } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import { Container } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Helmet } from "react-helmet";
import CtaBanner from "../components/cta-banner";
import HeroBanner from "../components/hero";
import Layout from "../components/layout";

const FaqPage = () => {
	const data = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					siteUrl
				}
			}
			pageData: wpPage(slug: { eq: "faq" }) {
				seoFields {
					localBusinessSchema
					metaDescription
					opengraphDescription
					opengraphTitle
					title
					image {
						node {
							altText
							sourceUrl
							mediaDetails {
								height
								width
							}
						}
					}
				}
				faqPageContent {
					faqContent {
						faqsData {
							answerContent
							questionText
						}
					}
					faqBannerSection {
						bannerSectionHeading
						bannerSectionContent
						bannerSectionBackground {
							node {
								altText
								sourceUrl
							}
						}
					}
					faqCta {
						faqCtaHeading
						faqCtaContent
						faqCtaButton {
							title
							target
							url
						}
						faqCtaBackgroundImage {
							node {
								altText
								sourceUrl
							}
						}
					}
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const {
		pageData: { seoFields, faqPageContent },
	} = data;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Faq",
				item: {
					url: `${siteUrl}/faq`,
					id: `${siteUrl}/faq`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={seoFields?.title}
				description={seoFields?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/faq`,
					title: `${seoFields?.opengraphTitle}`,
					description: `${seoFields?.opengraphDescription}`,
					images: [
						{
							url: `${seoFields?.image?.node.sourceUrl}`,
							width: `${seoFields?.image?.node.mediaDetails.width}`,
							height: `${seoFields?.image?.node.mediaDetails.height}`,
							alt: `${seoFields?.image?.node.altText}`,
						},
					],
				}}
			/>

			<Layout>
				<HeroBanner
					heading={faqPageContent?.faqBannerSection.bannerSectionHeading}
					content={faqPageContent?.faqBannerSection.bannerSectionContent}
					bgImage={
						faqPageContent?.faqBannerSection.bannerSectionBackground?.node
							?.sourceUrl
					}
					showUnderline={false}
					textAlign={"center"}
					columns={7}
				/>
				<section className="py-5 py-md-8">
					<Container>
						{faqPageContent.faqContent.faqsData.length > 0 && (
							<Row className="justify-content-center faq-wrapper">
								{faqPageContent.faqContent.faqsData.map((faqItems, i) => (
									<Col lg={7}>
										<h6 className="text-light-black">
											{faqItems.questionText}
										</h6>
										<div
											className="fs-6 text-light-black"
											dangerouslySetInnerHTML={{
												__html: faqItems.answerContent,
											}}
										></div>
									</Col>
								))}
							</Row>
						)}
					</Container>
				</section>

				<CtaBanner
					heading={faqPageContent?.faqCta.faqCtaHeading}
					content={faqPageContent?.faqCta.faqCtaContent}
					btnCta1={faqPageContent?.faqCta.faqCtaButton}
					bgImage={faqPageContent?.faqCta.faqCtaBackgroundImage?.node}
					textPosition="start"
					isGreen={true}
				/>
			</Layout>
		</>
	);
};

export default FaqPage;
